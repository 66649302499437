import React from 'react';
import Seo from '../components/Seo';
import ComponentList from '../components/ComponentList';

 export default ({pageContext, location}) => {
	const {seo = {}, components = [], hideFromSearchEngine, isLandingPage, customUtmRedirect, customModalContent, customStatusMessage} = pageContext;

	// Create a custom redirect URL if this is a landing page
	// const redirectLandingPage = isLandingPage
	// ? `/?utm_source=direct&utm_medium=podcast&utm_campaign=${customUtmRedirect}&discount=${customUtmRedirect}`
	// : false;
  // turn this back on to continue to use this functionality
  const redirectLandingPage = false;

	if(redirectLandingPage) {

		// push landing page data to state
		const landingPageData = { customUtmRedirect, customModalContent, customStatusMessage }
		if(typeof window !== 'undefined') {
			localStorage.setItem('landingPage', JSON.stringify(landingPageData));

			// redirect to the home page.
			window.location.href = redirectLandingPage
		}
	}

	const metaTitle = seo.metaTitle || `${pageContext.title} - Genexa`;
	const openGraphTitle =
		seo.openGraphTitle || `${pageContext.title} - Genexa`;
	const twitterTitle = seo.twitterTitle || `${pageContext.title} - Genexa`;

	return (
		<React.Fragment>
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={seo.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={seo.twitterImage}
				pathname={location.pathname}
				noIndexNoFollow={hideFromSearchEngine}
			/>
			<ComponentList components={components} />
		</React.Fragment>
	);
};
